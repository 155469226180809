.about p {
  margin-bottom: 2em;
}

.horaires {
  display: flex;
  justify-content: space-between;
  /* width: 23vw; */
  letter-spacing: 0.15em;
}

.horaires .days {
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: start;
}

.horaires .hours {
  font-size: 1.25rem;
  line-height: 1.75rem;
  align-self: flex-end;
}

form label {
  display: block;
  color: white;
  text-align: start;
  margin-bottom: 0.1rem;
}

form input,
form textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
}

form input:focus
form textarea:focus {
  outline: none !important;
}