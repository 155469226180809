@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Bold";
  src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "ExtraBold";
  src: url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Title";
  src: url("./assets/fonts/rockwell-bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    sans-serif;
}

.subtitle {
  text-transform: uppercase;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.15em;
  font-family: "Bold", sans-serif;
}

@media screen and (max-width: 640px) {
  .subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
}
